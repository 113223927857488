import Enum from './enum'

/**
 * インポート反映方法
 */
class PublishType extends Enum {
    static IMMEDIATE = 1; // 即時公開
    static DRAFT = 2; // 下書きとして保存

    static values() {
        return {
            [this.IMMEDIATE]: '即時公開',
            [this.DRAFT]: '下書きとして保存',
        }
    }
}

export default PublishType;
