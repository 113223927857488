<template>
    <page-title
        icon="bi-upload"
        title="インポート予約"
    >
        <button-back
            :to="{name: 'ImportJobList'}"
            text="インポート履歴へ"
        ></button-back>
    </page-title>

    <section class="section">
        <form @submit.prevent="save">
            <div class="form-group col-md-8 mb-3">
                <label>インポート形式</label>
                <form-select
                    v-model="import_format"
                    :options="import_formats"
                    :required="true"
                    empty_option="-- 形式を選択 --"
                ></form-select>
            </div>
            <div class="form-group col-md-8 mb-3">
                <label>CSVファイル</label>
                <input
                    type="file"
                    class="form-control"
                    accept="text/csv"
                    required
                    @change="csvUpload"
                >
            </div>
            <div class="form-group col-md-8 mb-5">
                <label>更新内容</label>
                <form-select
                    v-model="publish_type"
                    :options="publish_types"
                    :required="true"
                    empty_option="-- 更新内容を選択 --"
                ></form-select>
            </div>

            <div class="col-md-4">
                <button-exec-create
                    size="btn-lg"
                    text="インポート"
                ></button-exec-create>
            </div>
        </form>
    </section>
</template>

<script>
import ImportFormat from '@/models/enums/import-format';
import PublishType from '@/models/enums/publish-type';
import PageTitle from '@/components/PageTitle.vue';
import ButtonBack from '@/components/buttons/ButtonBack';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate';
import FormSelect from '@/components/forms/FormSelect';

export default {
    name: 'ImportJobAdd',
    components: {
        PageTitle,
        ButtonBack,
        ButtonExecCreate,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            // 送信する値
            import_format: null,
            publish_type: null,
            file_data: null,

            // 選択肢
            import_formats: ImportFormat.options(),
            publish_types: PublishType.options(),
        }
    },
    mounted() {
        //製品編集権限を持たない場合
        if (this.$store.getters['auth/canEditBasic']() === false) {
            this.showErrorMessage('アクセス権限がありません');
            this.$router.push({name: 'ProductGroupList'});
        }
    },
    methods: {
        csvUpload(e) {
            this.file_data = e.target.files[0];
        },

        save() {
            this.startScreenLoading();

            let header = {headers: {'content-type': 'multipart/form-data'}}; //fileを送るheader
            let form_data = new FormData();
            form_data.append("file", this.file_data);
            form_data.append('import_format', this.import_format);
            form_data.append('publish_type', this.publish_type);

            this.$http.post('/import', form_data, header)
            .then(() => {
                this.showMessage('予約しました');
                this.$router.push({name: 'ImportJobList'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
